// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-app-js": () => import("./../../../src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-hair-treatments-js": () => import("./../../../src/pages/hair-treatments.js" /* webpackChunkName: "component---src-pages-hair-treatments-js" */),
  "component---src-pages-hair-wigs-js": () => import("./../../../src/pages/hair-wigs.js" /* webpackChunkName: "component---src-pages-hair-wigs-js" */),
  "component---src-pages-index-backup-js": () => import("./../../../src/pages/index-backup.js" /* webpackChunkName: "component---src-pages-index-backup-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-interior-js": () => import("./../../../src/pages/interior.js" /* webpackChunkName: "component---src-pages-interior-js" */),
  "component---src-pages-saas-js": () => import("./../../../src/pages/saas.js" /* webpackChunkName: "component---src-pages-saas-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */)
}

